/* You can add global styles to this file, and also import other style files */
@use 'styles/breakpoints';

@use 'primeflex/primeflex' with (
  $sm: breakpoints.$sm,
  $md: breakpoints.$md,
  $lg: breakpoints.$lg,
  $xl: breakpoints.$xl
);

@import 'styles/global';
@import 'styles/animation';
@import 'styles/page-view';
@import 'styles/utilities';
@import '@adyen/adyen-web/dist/adyen.css';

/*
 App Wide CSS Properties are defined in libs/shared/styles/src/lib/styles/css-variables.scss
 because we want them also inside storybook
 Importing is done through project.json targets -> build -> styles array
*/
