@use 'styles/breakpoints';

.page-breadcrumbs {
  margin-top: var(--body-margin-top);
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  &.no-breadcrumbs {
    margin-top: var(--body-margin-top);
  }

  .page-section {
    width: 100%;
    max-width: 100%;

    @media all and (min-width: breakpoints.$desktop) {
      max-width: var(--page-container-max-width);
    }
  }

  .page-section-x-padded {
    padding: 0 var(--padding-3);

    @media all and (min-width: breakpoints.$tablet) {
      padding: 0 var(--padding-9);
    }
  }

  section {
    ppg-steps {
      margin-bottom: 40px;
      margin-top: 1.5rem;
    }

    // Matches all ppg-steps components except the first one within sequence of multiple ppg-steps
    // each of which is rendered inside ppg-shared-component-renderer
    ppg-shared-component-renderer:has(ppg-steps) ~ ppg-shared-component-renderer ppg-steps {
      margin-top: 0px;
    }

    // Matches the last ppg-steps component within sequence of multiple ppg-steps
    // each of which is rendered inside ppg-shared-component-renderer
    ppg-shared-component-renderer:has(ppg-steps):not(:has(+ ppg-shared-component-renderer ppg-steps)) ppg-steps {
      margin-bottom: 0px;
    }

    ppg-resource-list {
      margin-bottom: 16px;
      margin-top: 1.5rem;
    }

    // Matches all ppg-resource-list components except the first one within sequence of multiple ppg-resource-lists
    // each of which is rendered inside ppg-shared-component-renderer
    ppg-shared-component-renderer:has(ppg-resource-list) ~ ppg-shared-component-renderer ppg-resource-list {
      margin-top: 0px;
    }

    // Matches the last ppg-resource-list component within sequence of multiple ppg-resource-lists
    // each of which is rendered inside ppg-shared-component-renderer
    ppg-shared-component-renderer:has(ppg-resource-list):not(:has(+ ppg-shared-component-renderer ppg-resource-list))
      ppg-resource-list {
      margin-bottom: 0px;
    }
  }
}
