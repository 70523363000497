@use './breakpoints';
@use './sizes';
@use './spacing.scss';
@use './position.scss';

:root {
  --color-body-bg: #c1bdba;
  --color-form-bg: #13232f;
  --color-overlay-mask: #00000066;

  --color-main: #1ab188;
  --color-white: #fff;
  --color-black: #000;
  --color-logo: #21236e;
  --global-text-color: #495057;
  --text-color-secondary: #6c757d;

  --dialog-header-color: #343a40;

  --root-surface-border: #dfe7ef;

  --color-label-required: #e24c4c;
  --color-label-option: #9fa9b7;
  --color-line-divider: #dee2e6;
  --color-border-input: #ced4da;

  --color-link-color: var(--color-neutral-blue-00);

  --color-gray-light: #a0b3b0;
  --color-light-gray: #d3d3d3;
  --color-light-gray-200: #bac4cf;
  --color-bluegray-200: #bcc3cd;
  --color-bluegray-900: #282e38;
  --color-darkteal-blue: #002e43;
  --color-lightl-blue-00: #bfdbfe;
  --color-light-sky-00: #eff6ff;
  --color-dark-blue-00: #002e43;
  --root-surface-ground: #eff3f8;

  --color-yellow-500: #eab308;

  --color-green-700: #188a42;

  --color-neutral-00: #fff;
  --color-neutral-05: #f4f5f5;
  --color-neutral-25: #c8cbce;
  --color-neutral-50: #6b7886;
  --color-neutral-75: #5b636d;
  --color-neutral-black: #252f3d;
  --color-neutral-black-2: #181e28;
  --color-neutral-blue-00: #0074a9;
  --color-neutral-gray-50: #999;
  --color-hover: #005a84;

  --neutral-700: #3b4a54;
  --light-700: #044d6b;
  --light-500: #0078a9;
  --chip-bg-neutral-secondary-default: #eaeff5;
  --surface-500: #9e9e9e;

  --font-family-inter: 'Inter';

  --font-size-xxxs: #{sizes.$font-size-xxxs};
  --font-size-xxs: #{sizes.$font-size-xxs};
  --font-size-xxs-1: #{sizes.$font-size-xxs-1};
  --font-size-xs: #{sizes.$font-size-xs};
  --font-size-s: #{sizes.$font-size-s};
  --font-size-sm: #{sizes.$font-size-sm};
  --font-size-m: #{sizes.$font-size-m};
  --font-size-m-1: #{sizes.$font-size-m-1};
  --font-size-l: #{sizes.$font-size-l};
  --font-size-xl: #{sizes.$font-size-xl};
  --font-size-xxl: #{sizes.$font-size-xxl};
  --font-size-xxxl: #{sizes.$font-size-xxxl};
  --font-size-xxxxl: #{sizes.$font-size-xxxxl};

  --font-weight-light: #{sizes.$font-weight-light};
  --font-weight-regular: #{sizes.$font-weight-regular};
  --font-weight-medium: #{sizes.$font-weight-medium};
  --font-weight-semi-bold: #{sizes.$font-weight-semi-bold};
  --font-weight-bold: #{sizes.$font-weight-bold};

  --body-margin-left: #{spacing.$body-margin-left};
  --body-margin-right: #{spacing.$body-margin-right};
  --mobile-body-margin-left: #{spacing.$mobile-body-margin-left};
  --mobile-body-margin-right: #{spacing.$mobile-body-margin-right};
  --body-margin-top: #{spacing.$body-margin-top};
  --body-margin-bottom: #{spacing.$body-margin-bottom};

  --margin-0: #{spacing.$margin-0};
  --margin-1: #{spacing.$margin-1};
  --margin-2: #{spacing.$margin-2};
  --margin-3: #{spacing.$margin-3};
  --margin-4: #{spacing.$margin-4};
  --margin-5: #{spacing.$margin-5};
  --margin-6: #{spacing.$margin-6};
  --margin-7: #{spacing.$margin-7};
  --margin-8: #{spacing.$margin-8};
  --margin-9: #{spacing.$margin-9};
  --padding-0: #{spacing.$padding-0};
  --padding-1: #{spacing.$padding-1};
  --padding-2: #{spacing.$padding-2};
  --padding-3: #{spacing.$padding-3};
  --padding-4: #{spacing.$padding-4};
  --padding-5: #{spacing.$padding-5};
  --padding-6: #{spacing.$padding-6};
  --padding-7: #{spacing.$padding-7};
  --padding-8: #{spacing.$padding-8};
  --padding-9: #{spacing.$padding-9};

  --z-index-0: #{position.$z-index-0};
  --z-index-1: #{position.$z-index-1};
  --z-index-2: #{position.$z-index-2};
  --z-index-3: #{position.$z-index-3};
  --z-index-4: #{position.$z-index-4};

  // made for shared card components
  --color-arrow-up-right: #0074a9;
  --color-card-title-primary: #0078a9;
  --color-card-primary-black: #081520;
  --color-card-secondary-black: #3b4a54;
  --card-gaps: #{spacing.$card-gaps};
  --card-line-height-title: #{sizes.$card-line-height-title};
  --card-line-height-description: #{sizes.$card-line-height-description};

  // page-related
  --page-container-max-width: 90rem;
  --page-header-height: 11rem;
  --page-top-hero-image-max-width: 2560px; /*160rem*/
  --page-top-hero-image-height: 456px;
  --page-header-z-index: #{position.$z-index-3};

  // content banners
  --content-banner-transparent-theme-texts-color: var(--fg-primary);
  --content-banner-light-theme-texts-color: var(--fg-primary);
  --content-banner-light-theme-background-color: var(--bg-secondary);
  --content-banner-dark-theme-texts-color: var(--fg-white);
  --content-banner-dark-theme-background-color: var(--fg-brand-secondary-active);

  // article list filter
  --accordion-header-link-color: #f8f9fa;

  // PL&F - High Image Strip
  --pl-and-f-high-image-strip-title-color: #546f4d;
  --pl-and-f-high-image-strip-description-color: #31261d;
  --pl-and-f-high-image-strip-hover-border-color: #90a68a;
  --pl-and-f-high-image-strip-focus-border-color: ##0d2906;

  // PL&F - Modified Hero Banner
  --pl-and-f-hero-banner-cta-color: #68624b;
}
