@use 'styles/breakpoints';

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

body * {
  box-sizing: border-box;
}

:root {
  --space-none: 0;
  --space-xxs: 4px;
  --space-xs: 8px;
  --space-s: 12px;
  --space-base: 16px;
  --space-m: 20px;
  --space-l: 24px;
  --space-xl: 32px;
  --space-xxl: 40px;
}

.aa-Panel {
  z-index: var(--z-index-3);
}

.aa-Item[aria-selected='true'] {
  background-color: var(--bg-brand-primary);
}

.reserved {
  &::after {
    content: '®';
    position: relative;
    font-size: 0.875em;
    vertical-align: super;
  }
}

.form-container {
  width: 90%;
  max-width: 27.5rem;
}

.section-container {
  width: 100%;
  max-width: var(--page-container-max-width);
  margin-left: auto;
  margin-right: auto;

  padding-left: var(--padding-3);
  padding-right: var(--padding-3);

  @media all and (min-width: breakpoints.$tablet) {
    padding-left: var(--padding-9);
    padding-right: var(--padding-9);
  }

  &:first-child {
    padding-top: 2.5rem;
  }

  &:last-child {
    padding-bottom: 3.5rem;
  }
}

.sidebar-area {
  @media all and (min-width: breakpoints.$desktop) {
    .card-container .presentation-large-container {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }
  }
}
