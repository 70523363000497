@use './spacing.scss';
@use './breakpoints';

.u-cursor-pointer {
  cursor: pointer;
}

.u-text-wrap {
  text-wrap: wrap;
}

.u-break-word {
  word-break: break-word;
  &-overflow {
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

.u-break-all {
  word-break: break-all;
}

.u-overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.u-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-box-shadow-on-focus,
.no-box-shadow-on-focus > * {
  box-shadow: 0 0 0 !important;
}

/* --root-color-light-gray-200 should be replaced with the disabled-label-color value once the @pds provides us such value */
input[disabled],
*.disabled {
  color: #{var(--root-color-light-gray-200)} !important;
  pointer-events: none;

  > *,
  > * > *,
  > * > * > *,
  > * > * > * > * {
    color: #{var(--root-color-light-gray-200)} !important;
    pointer-events: none;
    fill: #{var(--root-color-light-gray-200)} !important;
  }
}

.layout-container {
  width: 100%;
  max-width: var(--page-container-max-width);
  margin-left: auto;
  margin-right: auto;
  margin-top: #{spacing.$margin-9};
  margin-bottom: #{spacing.$margin-9};
  padding-left: #{spacing.$padding-3};
  padding-right: #{spacing.$padding-3};

  @media all and (min-width: #{breakpoints.$md}) {
    padding-left: #{spacing.$padding-9};
    padding-right: #{spacing.$padding-9};
  }
}

@for $i from 1 through 20 {
  .min-w-#{$i}rem {
    min-width: #{$i}rem !important;
  }
}

.invalid-input-error-message {
  color: var(--fg-error-tertiary);
}
