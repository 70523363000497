@keyframes zoomInWithShift {
  0% {
    transform: scale(1);
    object-position: center;
  }

  50% {
    transform: scale(1.05);
    object-position: right;
  }

  100% {
    transform: scale(1);
    object-position: center;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
